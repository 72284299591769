<!-- Tela dos Dados do Novo Equipamento -->
 <template>
    <div class="pa-4">
        <v-form @submit.prevent="localOnSubmit" ref="formRef">
            <div class="grid-container">
                <v-text-field dense name="codigo" v-model="currentItem.codigo" outlined disabled label="Código"></v-text-field>
                <v-autocomplete dense name="descricao" v-model="currentItem.descricao" :rules="requiredField" outlined
                    :items="descricoes" item-text="nome" item-value="id" return-object clearable>
                    <template v-slot:label>
                        <span>Descrição <span style="color: red;">*</span></span>
                    </template>
                </v-autocomplete>
                <v-select dense :items="tipoEquipamentos" item-text="nome" item-value="id" return-object v-model="currentItem.tipoEquipamento" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Classificação <span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="quantidade" v-model="currentItem.quantidade" outlined :rules="requiredField" clearable>
                    <template v-slot:label>
                        <span>Quantidade <span style="color: red;">*</span></span>
                    </template>
                </v-text-field>
                <v-select dense :items="status" item-text="label" item-value="value" v-model="currentItem.ativo" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Status <span style="color: red;">*</span></span>
                    </template>
                </v-select>
            </div>
            <v-row class="justify-end dense">
                <v-col class="text-end">
                    <v-tooltip top :disabled="isFormValid">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                            </span>
                        </template>
                        <span>Preencha todos os campos obrigatórios (*) para habilitar o botão</span>
                    </v-tooltip>  
                </v-col>
        </v-row>
        </v-form>
    </div>
 </template>

<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
import ConfirmButton from '../../../ConfirmButton.vue'

const { withCRUDUtils } = UtilsFunc

export default {
    name: "NovoEquipamento",
    data: () => withCRUDUtils({
        descricoes: [],
        tipoEquipamentos: [],
        status: [
            {label: 'Ativo', value: true},
            {label: 'Inativo', value: false}
        ],
        isSubmitting: false,
        requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório']
    }),
    components: { ConfirmButton },
    props: {
        onSubmit: Function,
        loading: Boolean,
        formRef: Object,
        currentItem: { type: Object, 
            default: () => ({
                ativo: null
            })
        }
    },
    computed: {
        isFormValid() {
            return !!(
                this.currentItem.descricao?.id && 
                this.currentItem.tipoEquipamento?.id &&
                this.currentItem.quantidade &&
                this.currentItem.ativo !== null
            )
        }
    },
    methods: {
        async localOnSubmit() {
            const validation = this.$refs.formRef.validate()
            if(!validation) {
                this.$toast.error("Por favor, preencha todos os campos obrigatórios")
                return
            }
            this.isSubmitting = true
            this.onSubmit({
                ...this.currentItem,
                ativo: Boolean(this.currentItem.ativo),
                
            }).finally(() => {
                this.isSubmitting = false
            })
        },
        async getNomeEquipamento() {
            const LOADING_NAME = 'get:nomeequipamentos'
            this.setLoading(LOADING_NAME)
            const { data } = await api.get('equipamentos/nomeequipamentos/')
            this.setLoading(LOADING_NAME, true)
            this.descricoes = data.filter(descricao => descricao.ativo)
        },

        async getTipoEquipamento() {
            const LOADING_NAME = 'get:tipoequipamento'
            this.setLoading(LOADING_NAME)
            const { data } = await api.get('equipamentos/tipoequipamentos/')
            this.setLoading(LOADING_NAME, true)
            return this.tipoEquipamentos = data.filter(tipoEquipamento => tipoEquipamento.ativo)
        }
    },
    mounted() {
        this.getNomeEquipamento()
        this.getTipoEquipamento()
    }
}
</script>

<style scoped>
div.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

</style>
